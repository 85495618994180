* {
  font-family: "Comic Neue", -apple-system, BlinkMacSystemFont, "Segoe UI";
}

.Header,
.About,
.Tokenomics,
.Roadmap {
  margin: 3rem;
}

.About,
.Tokenomics,
.Roadmap {
  font-size: 1.5rem;
  height: 100%;
  color: #fff;

  > div {
    padding: 2rem;
    border-radius: 1rem;

    h1 {
      font-weight: 700;
    }

    .row {
      gap: 1rem;
      height: 20rem;
      overflow: auto;

      > .col {
        padding: 1rem;
        border-radius: 0.5rem;
        background: rgba(255, 255, 255);
        color: black;

        h3 {
          font-weight: 700;
          text-align: center;
        }
      }
    }
  }
}
