body {
  margin: 0;
  font-family: "Comic Neue", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(
    45deg,
    rgba(255, 0, 0, 0.7) 0%,
    rgba(255, 154, 0, 0.7) 10%,
    rgba(208, 222, 33, 0.7) 20%,
    rgba(79, 220, 74, 0.7) 30%,
    rgba(63, 218, 216, 0.7) 40%,
    rgba(47, 201, 226, 0.7) 50%,
    rgba(28, 127, 238, 0.7) 60%,
    rgba(95, 21, 242, 0.7) 70%,
    rgba(186, 12, 248, 0.7) 80%,
    rgba(251, 7, 217, 0.7) 90%,
    rgba(255, 0, 0, 0.7) 100%
  );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
